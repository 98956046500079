import React from "react"
import PropTypes from "prop-types"
import { CustomButtonWrapper } from "../styles/components/button"
import Button from '@material-ui/core/Button';
import CircularProgress from "@material-ui/core/CircularProgress";
import Colors from "../assets/colors";

const CustomButton = ({
  align, color, background, font, label, onClick, isLoading,
  indicatorColor, containerStyle, fullWidth, fullWidthMobile, disabled, id
}) => (
    <CustomButtonWrapper style={containerStyle}
                         indicatorColor={indicatorColor}
                         color={color}
                         background={background}
                         fullWidthMobile={fullWidthMobile}
                         font={font}
                         disabled={disabled}
                         align={align}>
        <Button variant="contained"
                fullWidth={fullWidth}
                id={id}
                className="custom-button-1"
                onClick={onClick}
                disabled={isLoading || disabled}>
            {
                isLoading &&
                <CircularProgress size={24}
                                  className="custom-indicator-1" />
            }
            {
                !isLoading && label
            }
        </Button>
    </CustomButtonWrapper>
);

CustomButton.propTypes = {
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    disabled: PropTypes.bool,
    indicatorColor: PropTypes.string,
    containerStyle: PropTypes.object,
    fullWidth: PropTypes.bool,
    fullWidthMobile: PropTypes.bool,
    align: PropTypes.oneOf([
        'center',
        'left',
        'right'
    ]),
    color: PropTypes.string.isRequired,
    disabledColor: PropTypes.string,
    background: PropTypes.string.isRequired,
    disabledBackground: PropTypes.string,
    font: PropTypes.string.isRequired
};

CustomButton.defaultProps = {
    isLoading: false,
    disabled: false,
    indicatorColor: '--four-color',
    containerStyle: {},
    fullWidth: true,
    fullWidthMobile: true,
    disabledColor: Colors.LIGHT_GRAY,
    disabledBackground: Colors.GRAY,
    align: 'left'
};

export default CustomButton;
