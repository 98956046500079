import styled from "styled-components"
import Colors from "../../assets/colors"
import Fonts from "../../assets/fonts"

export const FormWrapper = styled.div`
    width: 100%;
    min-height: calc(100vh - 110px);
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const LoginContainer = styled.div`
    width: 60%;
    min-height: 500px;
    height: auto;
    box-shadow: 0 3px 6px 0px rgba(0, 0, 0, 0.16);
    background-color: ${Colors.WHITE};
    
    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (max-width: 767px) {
        box-shadow: none;
        width: 100%;
    }
    
    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (min-width: 768px) and (max-width: 991px) {
        width: 100%;
    }
`;

export const LoginFormContainer = styled.div`
    padding: 30px 60px;
    margin: auto 0;
    
    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (max-width: 767px) {
        padding: 0;
        margin-top: 50px;
        margin-bottom: 50px;
    }
`;

export const LoginFormTitle = styled.label`
    font-family: ${Fonts.BOLD};
    font-size: 1.5em;
    color: ${Colors.BLACK};
`;


export const ButtonContainer = styled.div`
    text-align: center;
`;

export const LoginFormError = styled.span`
    font-family: ${Fonts.MEDIUM};
    font-size: 0.8em;
    color: ${Colors.ERROR};
`;
