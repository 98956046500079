import styled from "styled-components"

export const CustomButtonWrapper = styled.div`
    text-align: ${props => props.align};
    
    .custom-indicator-1 {
        color: var(${props => props.indicatorColor}) !important;
    }
    
    .custom-button-1 {
        color: ${props => props.disabled ? props.disabledColor : props.color} !important;
        background-color: ${props => props.disabled ? props.disabledBackground : props.background} !important;
        padding: 10px 60px !important;
        border-radius: 30px !important;
        font-family: ${props => props.font} !important;
        font-size: 1em !important;
    }
    
    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (max-width: 767px) {
        .custom-button-1 {
            padding: 10px 30px !important;
            width: ${props => props.fullWidthMobile ? '100%' : 'auto'};
        }
    }
    
    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (min-width: 768px) and (max-width: 991px) {
        .custom-button-1 {
            padding: 10px 30px !important;
            width: ${props => props.fullWidthMobile ? '100%' : 'auto'};
        }
    }
`;
