import React, {Component} from "react";
import Colors from "../assets/colors";
import Fonts from "../assets/fonts";
import Strings from "../assets/strings";
import CustomInput from "./custom-input";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import {MdVisibility, MdVisibilityOff} from "react-icons/md";
import CustomButton from "./button";
import {login} from "../data/services/auth";
import PropTypes from 'prop-types';
import {
  ButtonContainer,
  FormWrapper,
  LoginContainer,
  LoginFormContainer, LoginFormError,
  LoginFormTitle
} from "../styles/components/login-form"
import { navigate } from "@reach/router"


class LoginForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      remember: false,
      submitted: false,
      errors: {
        email: true,
        password: true,
      },
      isLoading: false,
      show_password: false,
      error: null
    };
  }

  render() {
    return (
      <FormWrapper>
        <LoginContainer>
          <LoginFormContainer>
            <LoginFormTitle>
              {Strings.ADMINS_LOGIN_TITLE}
            </LoginFormTitle>
            <form noValidate autoComplete="off">
              <br/>
              <CustomInput label={Strings.EMAIL + ' *'}
                           name="email"
                           id="email"
                           value={this.state.email}
                           color={Colors.BLACK}
                           required={true}
                           submitted={this.state.submitted}
                           invalid={this.state.errors['email']}
                           onChange={(val, v) => this.updateState('email', val, v)}
                           type="email"/>
              <br/>
              <CustomInput label={Strings.PASSWORD + ' *'}
                           name="password"
                           id="password"
                           value={this.state.password}
                           required={true}
                           color={Colors.BLACK}
                           submitted={this.state.submitted}
                           invalid={this.state.errors['password']}
                           onChange={(val, v) => this.updateState('password', val, v)}
                           inputProps={{
                             endAdornment: (
                               <InputAdornment position="end">
                                 <IconButton onClick={() => this.setState({
                                   show_password: !this.state.show_password
                                 })}>
                                   {
                                     this.state.show_password
                                       ? <MdVisibility/>
                                       : <MdVisibilityOff/>
                                   }
                                 </IconButton>
                               </InputAdornment>
                             )
                           }}
                           type={this.state.show_password ? 'text' : 'password'}/>
              <br/>
              <br/>

              <ButtonContainer>
                <CustomButton label={Strings.SIGNIN}
                              align="center"
                              onClick={() => this.handleSignup()}
                              color={Colors.WHITE}
                              isLoading={this.state.isLoading}
                              fullWidth={false}
                              background={Colors.BLACK}
                              font={Fonts.BOLD}/>
                {
                  this.state.error &&
                  <LoginFormError>
                    <br/>
                    {this.state.error}
                  </LoginFormError>
                }
                <br/>
                <br/>

              </ButtonContainer>
            </form>
          </LoginFormContainer>
        </LoginContainer>
      </FormWrapper>
    );
  }

  /**
   * Update state vars
   * @author @leonard_lib
   * @date 29/07/2020
   * @param prop
   * @param value
   * @param valid
   */
  updateState(prop, value, valid) {
    const state = this.state;
    const errors = state.errors;

    state[prop] = value;
    errors[prop] = !valid;
    state.errors = errors;

    this.setState(state);
  };

  /**
   * Check if data is completed
   * @author @leonard_lib
   * @date 29/07/2020
   * @returns {boolean|boolean}
   */
  checkCompleted() {
    return !this.state.errors['email']
      && !this.state.errors['password'];
  }

  /**
   * Handle signup button click
   * @author @leonard_lib
   * @date 29/07/2020
   */
  handleSignup = async () => {
    this.setState({
      submitted: true,
      error: null
    }, async () => {
      if (this.checkCompleted()) {
        this.setState({
          isLoading: true
        }, async () => {
          await this.makeCall();
        })
      }
    });
  };

  /**
   * Make call to login
   * @author @leonard_lib
   * @date 29/07/2020
   */
  makeCall = async () => {
    try {
      const a = await login(
        this.state.email,
        this.state.password
      );
      navigate("/docs")
    }
    catch(e) {
      this.setState({
        isLoading: false,
        error: e.message
      });
    }
  }
}

LoginForm.propTypes = {
  onLogin: PropTypes.func.isRequired,
};

export default LoginForm;
