import React from "react"
import { Router } from "@reach/router"
import Layout from "../components/layout"
import DocsList from "../components/docs-list"
import PrivateRoute from "../components/private-route"

const Docs = () => (
  <Layout>
    <Router>
      <PrivateRoute path="/docs/" component={DocsList} />
    </Router>
  </Layout>
)
export default Docs
