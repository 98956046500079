import React, { useEffect } from "react"
import { navigate } from "gatsby"
import { userIsAuthenticated } from "../data/services/auth"

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  useEffect(() => {
      async function checkAuthentication() {
        if (!userIsAuthenticated() && location.pathname !== `/login`) {
          navigate("/login")
          return null
        }
      }
      checkAuthentication()
    },
    []
  )


  return <Component {...rest} />
}

export default PrivateRoute
