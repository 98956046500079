/**
 * Strings in the app
 * @author @leonard_lib
 * @date 29/07/2020
 */
export default class Strings {
    static AFISOFOM_REQUEST = 'Solicitar crédito';

    static AFISOFOM_ANNUAL_INTEREST = 'Tasa de interés anual';

    static STATUS = 'Estatus';
    static CLEAR = 'Limpiar';

    static AFISOFOM_LOGIN_SUB_2 = 'aquí.';

    static UPDATE_AFISOFOM_CREDIT_ERROR = 'No se pudo actualizar el crédito del usuario.';
   static APPROVE_REQUEST_MSG = 'Al aprobar la solicitud de crédito estás conforme con la información definida actualmente: cantidad requerida, plazo, tipo de crédito, tasa de interés. Aprueba solamente si estás seguro de que la información del usuario es correcta.';
    static DECLINE_REQUEST_MSG_1 = 'Para rechazar la solicitud de';
    static DECLINE_REQUEST_MSG_3 = '. Inmediatamente le notificaremos al usuario que su crédito ha sido rechazado.';


    // AFISERVICIOS
   static AFISERVICIOS_REQUEST = 'Solicitar asesoría';


    // REGISTRO
    static AFISOFOM_REGISTER_SUBTITLE = 'Para continuar con la solicitud, es necesario que te registres en nuestra plataforma, también podrás consultar tus estados de cuenta y el estatus de tu crédito.';


    // SOLICITUD UNO / FORM ONE
    static AFISOFOM_FORMONE_TITLE = 'SOLICITUD DE CRÉDITO';

    static AFISOFOM_FORMONE_BTN_PMORAL = 'Persona moral';

    static AFISOFOM_FORMONE_TABLETITLE = 'Calendario de amortización.';

    static CANCEL = 'Cancelar';
    static DECLINE_2 = 'rechazar';

    // SOLICITUD REQUERIDA

    static AFISOFOM_SOLREQ_MAIN_TITLE = 'SOLICITUD DE CRÉDITO';

    static AFISOFOM_SOLREQ_MAIN_TXT_3 = ' e iniciando sesión con tu correo y contraseña, de igual forma los documentos pueden ser enviados a creditos@afisofom.com';



    // CONFIRMAR SOLICITUD
    static AFISOFOM_CONFIRMFORM_TITLE = 'CONFIRMAR SOLICITUD';

    static AFISOFOM_CONFIRMFORM_BTNGROUP_TEXT = 'Solicitar como:';




    static DEPLOY_CONFIRM_DESCRIPTION =`¿Estás seguro de realizar el despliegue? Los cambios serán visibles en el sitio <b><a target="_blank" href="{1}">{2}</a></b>.<br>
                   Es recomendable revisar la <b><a target="_blank" href="https://us-east-2.console.aws.amazon.com/amplify/home?region=us-east-2#/">consola de AWS</a></b> para revisar el estado de los despliegues.                         
`

    static DEPLOY_SITE = 'Desplegar sitio'
    static DEPLOY = 'Desplegar'


    static ADMINS_LOGIN_TITLE = 'Acceso a documentación';

    static SITES = 'Sitios';

    static CREATE_ADVISOR = 'Crear asesor';

    static MANAGE_CLIENT = 'Gestionar cliente';
    static MANAGE_ADVISOR_2 = 'Modifica los datos personales del asesor';

    static SEND_ADVISOR_INVITE_ERROR = 'Ocurrió un error al enviar la invitación al asesor, intenta de nuevo.';


    static PHONE_1 = 'Tel. 1: (55) 26140920';

    static MAP_URL = 'https://goo.gl/maps/cgWQy6Y7CH6VwGH9A';
    static REMEMBER_USER = 'Recordar usuario';
    static LOGOUT = 'Cerrar sesión';
    static REGISTER = 'Registro';

    static LASTNAME_2 = 'Apellido paterno (representante legal)';
    static STREET_LINE = 'Calle y número';
    static COLONY = 'Colonia';
    static CITY = 'Ciudad';

    static MOTHER_LASTNAME = 'Apellido materno';

    static SIGNIN = 'Ingresar';
    static FORGOT_PASSWORD = '¿Olvidaste tu contraseña?';
    static EMAIL = 'Correo electrónico';
    static PASSWORD = 'Contraseña';

    static FIELD_REQUIRED = 'Este campo es obligatorio';
    static INVALID_EMAIL = 'Ingresa un email valido';
    static FIELD_OVERLOAD_SIZE = 'Ingresa un valor correcto';
    static INVALID_VALUE = 'El valor es incorrecto';

    static PASSWORD_LENGTH = 'Mínimo 8 caracteres';
    static PASSWORD_UPPER = 'Al menos una letra mayúscula';
    static PASSWORD_LOWER = 'Al menos una letra minúscula';

    static GET_ADMIN_DATA_ERROR = 'No se pudo obtener la información del usuario.';

    static CONFIRM_SIGNUP_SUBTITLE = 'Por favor introduce el código que enviamos a tu correo electrónico para confirmar tu registro.';

    static SEND_ATTACHMENTS_ERROR = 'Ocurrió un error al enviar los archivos, intenta de nuevo.';

    static SEND_CONFIRM_CREDIT_ERROR = 'Ocurrión un error al envíar el correo al asesor.'


    static TABLE_ROWS = "registros";

    static LOAD_MORE = "Cargar más";

  static USER = "user"
  static OPERATION = "operation"
  static MAINTENANCE = "maintenance"

}
