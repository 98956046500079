import React, { useEffect, useState } from "react"
import { graphql, navigate } from "gatsby"
import { userIsAuthenticated } from "../data/services/auth"
import Layout from "../components/layout"
import { Drawer, List, ListItem, MuiThemeProvider } from "material-ui"
import { ListItemText, ListSubheader } from "@material-ui/core"
import clsx from "clsx"
import { makeStyles } from "@material-ui/core/styles"
import { SectionTitleSmall, MenuButton } from "../styles/global"
import { FaArrowLeft, FaBars } from "react-icons/fa"

const useStyles = makeStyles({
  list: {
    width: 250
  },
  fullList: {
    width: "auto"
  }
})

export default function Entry({ data, doc }) {
  // Drawer setup
  const classes = useStyles()
  const [openDrawer, setOpenDrawer] = useState(false)

  useEffect(() => {
      async function checkAuthentication() {
        if (!userIsAuthenticated()) {
          navigate("/login")
          return null
        }
      }
      checkAuthentication()
    },
    []
  )

  const toggleDrawer = (open) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return
    }
    setOpenDrawer(open)
  }

  const openLink = (edge) => {
    navigate(getSlug(edge.node.frontmatter))
  }

  const getSlug = (frontmatter) => {
    switch (frontmatter.doc) {
      case "maintenance":
        return "/docs-mantenimiento/" +  frontmatter.slug;
      case "user":
        return "/docs-usuario/" +  frontmatter.slug;
      case "operation":
        return "/docs-operacion/" +  frontmatter.slug;
      default:
        return "";
    }
  }

  const { entry, otherEntries } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = entry
  console.log(otherEntries)
  const list = (data) => (
    <div
      className={clsx(classes.list)}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        <ListSubheader>
          <div style={{float: "right", display: "block"}}>
            <FaArrowLeft onClick={toggleDrawer(false)}/>
          </div>
          <SectionTitleSmall>
            { frontmatter.doc === "maintenance" && "Manual de mantenimiento" }
            { frontmatter.doc === "user" && "Manual de usuario" }
            { frontmatter.doc === "operation" && "Manual de operación" }
          </SectionTitleSmall>
        </ListSubheader>
        {
          data.map((edge, key) => (
            <ListItem onClick={() => {openLink(edge)}} key={edge.node.frontmatter.slug}>
              <ListItemText primary={edge.node.frontmatter.position + ". " +edge.node.frontmatter.title}/>
            </ListItem>
          ))
        }
      </List>
    </div>
  )

  return (
    <MuiThemeProvider>
      <Drawer anchor={"left"} open={openDrawer} variant={"temporary"} onClose={toggleDrawer(false)}>
        {list(otherEntries.edges)}
      </Drawer>
      <MenuButton>
        <FaBars onClick={toggleDrawer(true)}/>
      </MenuButton>
      <Layout location={"docs"}>
        <div className="blog-post-container">
          <div className="blog-post">
            <SectionTitleSmall style={{textTransform: "uppercase"}}>
              {frontmatter.doc === "maintenance" && "Manual de mantenimiento"}
              {frontmatter.doc === "user" && "Manual de usuario"}
              {frontmatter.doc === "operation" && "Manual de operación"}
            </SectionTitleSmall>
            <div
              className="blog-post-content"
              dangerouslySetInnerHTML={{ __html: html }}
            />
          </div>
        </div>
      </Layout>
    </MuiThemeProvider>
  )
}

export const pageQuery = graphql`
    query($id: String!, $doc: String!) {
        entry: markdownRemark (frontmatter: { id: { eq: $id } }) {
            html
            frontmatter {
                id
                doc
                title
                slug
            }
        }
        otherEntries: allMarkdownRemark (
            filter: {frontmatter: {doc:{eq: $doc}}}
            sort: {fields: frontmatter___position}){
            edges {
                node {
                    frontmatter {
                        title
                        position
                        doc
                        slug
                    }
                }
            }
        }
    }
`
