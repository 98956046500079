import styled from "styled-components";
import Fonts from "../assets/fonts";
import Colors from "../assets/colors";

export const SectionTitle = styled.h1 `
  font-family: ${Fonts.BOLD};
  font-size: 1.5em;
  color: ${Colors.PRIMARY_AFI_SOFOM};
`;

export const SectionTitleSmall = styled.span`
  font-family: ${Fonts.BOLD};
  font-size: 1em;
  color: ${Colors.DARK_GRAY};
`

export const SectionSubtitle = styled.h2 `
  font-family: ${Fonts.MEDIUM};
  font-size: 1.5em;
  color: ${Colors.BLACK};
`

export const DefaultParagraph = styled.p `
  font-family: ${Fonts.REGULAR};
  line-height: 1.6em;
  font-size: 1em;
`

export const DefaultLink = styled.a `
	font-family: ${Fonts.BOLD};
	color: ${Colors.PRIMARY_AFI_SOFOM};
	text-decoration: underline;
	font-size: 1em;
`;

export const SmallLink = styled.a`
	font-family: ${Fonts.MEDIUM};
	color: ${Colors.GRAY};
	text-decoration: underline;
	font-size: 0.8em;
	cursor: pointer;
	margin-top: 5px;
`;

export const PageTitle = styled.span`
    font-family: ${Fonts.BRANDON_BOLD};
    font-size: 2em;
    color: ${props => props.color ? props.color : Colors.DARK_GRAY};
    text-transform: ${props => props.transform ? props.transform : 'uppercase'};
`;

export const DefaultText = styled.span`
    line-height: 30px;
    font-family: ${Fonts.MEDIUM};
    font-size: 1em;
    color: ${props => props.color ? props.color : Colors.DARK_GRAY};
`;

export const ErrorText = styled(DefaultText) `
  color: ${Colors.PRIMARY_AFI_SOFOM};
  font-size: .8em;
  line-height: 1.5em;
`
export const InfoMsg = styled.span`
    font-family: ${Fonts.MEDIUM};
    font-size: 1em;
    color: ${Colors.GRAY};
`;

export const ErrorMsg = styled.span`
    font-family: ${Fonts.MEDIUM};
    font-size: 0.8em;
    color: ${Colors.ERROR};
`;

export const MenuButton = styled.div`
  position: fixed;
  background: var(--primary-color);
  width: 100px;
  height: 100px;
  top: 20px;
  display: flex;
  border-radius: 0 30px 30px 0 ;
  z-index: 1001;
  justify-content: center;
  
  & > * {
    fill: white;
    font-size: var(--icon-size);
    align-self: center;
    justify-self: center;
    cursor: pointer;
  }
`


