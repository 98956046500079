import React, { useState } from "react"
import {FrameContainer} from "../styles/components/frame"
import SiteFrame from "./frame"
import Strings from "../assets/strings"

const DocsList = () => {
  const [docs, setDocs] = useState([
    Strings.USER, Strings.MAINTENANCE, Strings.OPERATION
  ])
  return (
    <>
    <h1>Documentación de proyectos</h1>
    <FrameContainer>
      {docs.map((doc, key) => (
        <SiteFrame site={doc} key={key}/>
      ))}
    </FrameContainer>
    </>
  )
}

export default DocsList
