import React from "react";
import LoginForm from "../components/login-form";
import Layout from "../components/layout"

const Login = ({ history }) => (
  <Layout history={history} >
    <div className="container">
      <LoginForm
        onLogin={() =>
          console.log("logged in")
        }
      />
    </div>
  </Layout>
);

export default Login;
