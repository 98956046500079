import React from "react"
import { Wrapper, Frame, FrameTitle } from "../styles/components/frame"
import { Link } from "gatsby"
import Engranaje  from "../assets/svg/engranaje.svg"
import Calendario  from "../assets/svg/calendario.svg"
import Usuario  from "../assets/svg/usuario.svg"
import Strings from "../assets/strings"

const SiteFrame = ({ site }) => (
    <Wrapper>
      {site === Strings.USER && (
        <Link to={"/docs-usuario/introduccion"}>
          <Frame>
            <Usuario style={{width: 150}}/>
            <FrameTitle>Manual de usuario</FrameTitle>
          </Frame>
        </Link>
      )}
      {site === Strings.MAINTENANCE && (
        <Link to={"/docs-mantenimiento/introduccion"}>
          <Frame>
            <Engranaje style={{width: 150}}/>
            <FrameTitle>Manual de mantenimiento</FrameTitle>
          </Frame>
        </Link>
      )}
      {site === Strings.OPERATION && (
        <Link to={"/docs-operacion/introduccion"}>
          <Frame>
            <Calendario style={{width: 150}}/>
            <FrameTitle>Manual de operación</FrameTitle>
          </Frame>
        </Link>
      )}
    </Wrapper>
  )

export default SiteFrame
