import React, { Component, useState } from "react"
import TextField from "@material-ui/core/TextField"
import PropTypes from "prop-types"
import Strings from "../assets/strings"
import { CustomInputWrapper } from "../styles/components/custom-input"


const CustomInput = props => {
  const [errorMsg, setErrorMsg] = useState(Strings.FIELD_REQUIRED),
    valueParams = props.defaultValue ?
      { defaultValue: props.defaultValue } :
      { value: props.value }

  const onChange = (event) =>
  {
    const value = event.target.value || ""
    const valid = checkValid(value)
    return props.onChange(value, valid)
  }

  const checkValid = value =>
  {
    let valid = true
    let msg = null

    if (props.required) {
      if (!(value != null && value !== "")) {
        valid = false
        msg = Strings.FIELD_REQUIRED
      }
    }

    if (props.maxLength != null) {
      if (value.length > props.maxLength) {
        valid = false
        msg = Strings.FIELD_OVERLOAD_SIZE
      }
    }

    if (props.minLength != null) {
      if (value.length < props.minLength) {
        valid = false
        msg = Strings.FIELD_OVERLOAD_SIZE
      }
    }

    if (props.sameAs != null) {
      if (value !== props.sameAs) {
        valid = false
        msg = Strings.INVALID_VALUE
      }
    }

    if (props.type === "email") {
      if (!isEmail(value)) {
        valid = false
        msg = Strings.INVALID_EMAIL
      }
    }

    setErrorMsg(msg)
    return valid
  }


  const isEmail = value =>
  {
    const regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
    return regex.test(value)
  }

  return (
    <CustomInputWrapper style={props.style}
                        className={props.className}
                        color={props.color}>


      <TextField error={props.invalid && props.submitted}
                 id={props.id}
                 name={props.name}
                 multiline={props.type === "textarea"}
                 rowsMax={props.rowsMax}
                 rows={props.rowsMax}
                 type={props.type}
                 onKeyDown={props.onKeyDown}
                 disabled={props.disabled}
                 fullWidth={props.fullWidth}
                 onChange={(e) => onChange(e)}
                 helperText={
                   props.invalid && props.submitted
                     ? errorMsg
                     : props.hint
                 }
                 label={!props.onlyPlaceholder ? props.label : ""}
                 placeholder={props.onlyPlaceholder ? props.placeholder : ""}
                 InputProps={props.inputProps}
                 {...valueParams}
      />
    </CustomInputWrapper>
  )
}

CustomInput.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  rowsMax: PropTypes.number,
  invalid: PropTypes.bool,
  submitted: PropTypes.bool,
  style: PropTypes.object,
  hint: PropTypes.string,
  value: PropTypes.string,
  inputProps: PropTypes.object,
  minLength: PropTypes.number,
  maxLength: PropTypes.number,
  disabled: PropTypes.bool,
  color: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  onlyPlaceholder: PropTypes.bool,
  fullWidth: PropTypes.bool,
  sameAs: PropTypes.string,
  onKeyDown: PropTypes.func,
  accept: PropTypes.string,
  uploaded: PropTypes.bool,
  isID: PropTypes.bool
}

CustomInput.defaultProps = {
  required: false,
  rowsMax: 0,
  invalid: false,
  submitted: false,
  style: {},
  inputProps: {},
  hint: "",
  minLength: null,
  maxLength: null,
  disabled: false,
  placeholder: "",
  className: "",
  sameAs: null,
  onlyPlaceholder: false,
  fullWidth: true
}

export default CustomInput
