import React from "react";
import styled from "styled-components";
import "../../components/layout.css"

export const Wrapper = styled.div`
  display: inline;
`

export const FrameContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-items: center;
  
  @media only screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }
`

export const Frame = styled.div`
  background-color: var(--light-color);
  border: 1px solid var(--light-color);
  width: 300px;
  height: 300px;
  box-sizing: border-box;
  transition: 0.5s background-color;
  display: flex;
  flex-direction: column;
  padding-top: 50px;
  align-items: center;
  &:hover {
    background-color: white;
  }
  
  & > * {
    width: 300px;
    align-self: center;
  }
`

export const FrameTitle = styled.span`
  text-align: center;
  text-decoration: none !important;
  color: var(--primary-color)
`



