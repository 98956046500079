import styled from "styled-components"
import Fonts from "../../assets/fonts"
import Colors from "../../assets/colors"


export const CustomInputWrapper = styled.div`
    margin-top: 20px;
    
    .MuiFormLabel-root {
        font-family: ${Fonts.MEDIUM} !important;
        font-size: 1em !important;
        color: ${Colors.BLACK} !important;
    }
    
    .MuiButton-root {
        font-family: ${Fonts.BOLD} !important;
        font-size: 1em !important;
    }
    
    .MuiInputBase-input {
        font-family: ${Fonts.MEDIUM} !important;
        font-size: 1em !important;
    }
    
    .MuiFormLabel-root {
        color: ${Colors.BLACK} !important;
    }
    
    .MuiFormLabel-root.Mui-focused {
        color: ${props => props.color} !important;
    }
    
    .MuiInput-underline:before {
        border-bottom: 1px solid ${Colors.GRAY} !important;
    }
    
    .MuiInput-underline:after {
        border-bottom: 2px solid ${props => props.color} !important;
    }
    
    .MuiFormLabel-root.Mui-error {
        color: ${Colors.ERROR} !important;
    }
    
    .MuiInput-underline.Mui-error:before {
        border-bottom: 1px solid ${Colors.ERROR} !important;
    }
    
    .MuiInput-underline.Mui-error:after {
        border-bottom: 2px solid ${Colors.ERROR} !important;
    }
    
    .MuiFormHelperText-root.Mui-error {
        font-family: ${Fonts.MEDIUM} !important;
        font-size: 1em !important;
    }
    
    .MuiTypography-body1 {
        font-family: ${Fonts.MEDIUM} !important;
        font-size: 1em !important;
    }
    
    .MuiCheckbox-colorPrimary.Mui-checked {
        color: ${props => props.color} !important;
    }
    
    .MuiPaper-root {
        background-color: var(--primary-color) !important;
        color: ${Colors.WHITE} !important;
    }
    
    .pseudo-button {
      background-color: ${Colors.DARK_GRAY} !important;
      color: ${Colors.WHITE} !important;
      display: inline-block;
      padding: 15px 30px;
      margin-bottom: 20px;
      border-radius: 30px;
      cursor: pointer;
    }
    
    .pseudo-button:hover {
      background-color: ${Colors.BLACK};
    }
    
    .pseudo-button:active {
      background-color: ${Colors.WHITE};
      color: ${Colors.DARK_GRAY}
    }
`;

